import { getValueOf } from '../style-migration-utils';

export function getNewServicePageData(oldServicePageProperties) {
  const headerVisibility = false;
  const gallerySliderLoopImages = false;
  const gallerySliderAutoSlide = false;
  const oldSettingsOfferingPageImageDimension = getValueOf(oldServicePageProperties['param_font_offeringPageImageDimension'])
  const bodyAlignment = getValueOf(oldServicePageProperties['param_font_offeringPageAlignmentTitle']);
  const policySectionTitle = getValueOf(oldServicePageProperties['param_font_offeringPageTextCancellationPolicy']);
  const serviceUnavailableMessageText = getValueOf(oldServicePageProperties['param_font_offeringPageTextServiceUnavailable']);
  const bookButtonText = getValueOf(oldServicePageProperties['param_font_offeringPageTextButton']);
  const pendingApprovalButtonText = getValueOf(oldServicePageProperties['param_font_offeringPagePendingTextButton']);
  const newServicePagedData = {
    gallerySliderLoopImages,
    gallerySliderAutoSlide,
    headerVisibility,
    bodyAlignment,
    policySectionTitle,
    serviceUnavailableMessageText,
    bookButtonText,
    pendingApprovalButtonText,
  }
  if (oldSettingsOfferingPageImageDimension !== 'none')
    newServicePagedData['gallerySliderImageResizeOption'] = oldSettingsOfferingPageImageDimension === 'ratio_1_1' ? 'crop' : undefined;

  Object.keys(newServicePagedData).forEach(key => newServicePagedData[key] === undefined && delete newServicePagedData[key]);
  return newServicePagedData;
}
