export type Font = {
  style: {bold: boolean, italic: boolean},
  family: string,
  preset: string,
  displayName: string
} | null;
export type Color = string | undefined | Object;
export type Layout = 'STRIP' | 'GRID' | 'CLASSIC';
export type CategoryLayout = 'tabs' | 'tags' | 'dropdown';
export type ImageType = 'square' | 'round' | 'none';

export const mapColor = (color, colorsMap, alpha = 1) => {
  function hexToRgbA(hex){
    if (alpha === undefined) {
      alpha = 1;
    }
    let colorPart;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      colorPart= hex.substring(1).split('');
      if(colorPart.length== 3){
        colorPart= [colorPart[0], colorPart[0], colorPart[1], colorPart[1], colorPart[2], colorPart[2]];
      }
      colorPart= '0x'+colorPart.join('');
      return 'rgba('+[(colorPart>>16)&255, (colorPart>>8)&255, colorPart&255].join(',')+',' + alpha + ')';
    }
    throw new Error(`${color} is a bad Hex`);
  }
  if (color) {
    switch (color.charAt(0)) {
      case '#':
        return {value: hexToRgbA(color)};
      case 'r':
        return {value: color};
      case 'h':
        return {value: color};
      default:
        return {value: hexToRgbA(colorsMap[color]), themeName: color};
    }
  } else {
    return undefined;
  }
};

export const mapFont = (font, size = 16) => {
  if (font) {
    const parsedFont = JSON.parse(font);
    return {
      size,
      style: {bold: false, italic: false},
      family: parsedFont.value,
      preset: 'Custom',
      displayName: 'Custom'
    }
  } else {
    return undefined;
  }
};

export const getValueOf = (property) => property && (JSON.parse(property).cssFontFamily || JSON.parse(property).value);
