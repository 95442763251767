import { BOOKINGS_DEF_ID, PageId } from '../constants';
import { getBookingsDefId } from './editor-sdk-actions';

export async function addBookingsPagesAsPanel(editorSDK, appToken) {
  const bookingsDefId = await getBookingsDefId(editorSDK);
  const bookingsTpaApplicationId = (
    await editorSDK.tpa.app.getDataByAppDefId(appToken, bookingsDefId)
  ).applicationId;
  const allSitePages = await editorSDK.pages.data.getAll();
  const bookingsPages = allSitePages.filter(
    (page) =>
      page.tpaApplicationId === bookingsTpaApplicationId &&
      page.tpaPageId !== PageId.BOOKINGS_MEMBERS_AREA,
  );
  return Promise.all(
    bookingsPages.map((bookingPage) =>
      editorSDK.pages.data.update(appToken, {
        pageRef: { id: bookingPage.id, type: bookingPage.type },
        data: { managingAppDefId: bookingsDefId },
      }),
    ),
  );
}

export async function createBookCheckoutState(editorSDK, appToken, allSitePages) {
  const bookCheckoutPageData = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_CHECKOUT,
  );
  if (bookCheckoutPageData) {
    await editorSDK.document.pages.setState(appToken, {
      state: {
        bookCheckoutPage: [{ id: bookCheckoutPageData.id }],
      },
    });
  }
}

export async function createServicePageState(editorSDK, appToken, allSitePages) {
  const servicePageData = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_SERVICE_PAGE,
  );
  if (servicePageData) {
    await editorSDK.document.pages.setState(appToken, {
      state: {
        servicePage: [{ id: servicePageData.id }],
      },
    });
  }
}

export async function handleOpenBookingsPagesPanel(editorSDK, appToken) {
  const { check, show } = editorSDK.editor.deeplink;
  const pagesPanel = {
    type: 'pagesPanel',
    params: [BOOKINGS_DEF_ID],
  };
  const editorDeepLinkToBookingsPagesPanel = await check(appToken, pagesPanel);
  editorDeepLinkToBookingsPagesPanel && (await show(appToken, pagesPanel));
}

