import {closeBar, openBar, setBarStep} from '../progressbar-utils';
import {
  getBookingsDefId,
  getPageData,
  getTPAMainSectionPageRef,
  getTPAMainSectionStructure, updatePageData
} from '../editor-sdk-actions';
import {MigrationHandler} from '../../migration/migration-handler';
import {getSchedulerStyles, setBookingsListStyles} from './schedule-styles-migration';
import {PageId, WidgetsId} from '../../constants';
import {addBookCheckoutPage} from '../pages-actions';
import {addBookingsPagesAsPanel, createBookCheckoutState} from '../pages-panel-actions';
import {navigateToBookingsList} from '../navigation-actions';

export async function handleListMigration(sdk, appToken, translation, enableProgressBar = true) {
  if (enableProgressBar) {
    await openBar(sdk, appToken, translation,'bookings-migration.progress-bar.title', 3);
    await setBarStep(sdk, appToken, translation,'bookings-migration.progress-bar.step',  1);
  }
  const bookingsDefId = await getBookingsDefId(sdk);
  const colorsMap = await sdk.document.theme.colors.getAll();
  const migratory = new MigrationHandler(sdk, appToken, bookingsDefId);
  const schedulerSectionStructure = await getTPAMainSectionStructure(sdk, appToken, bookingsDefId);
  if (!schedulerSectionStructure) {
    // Bookings not installed, migration succeeded emptily.
    await closeBar(sdk, appToken, false);
    return Promise.resolve();
  }
  const schedulerSectionRef = await getTPAMainSectionPageRef(sdk, appToken, bookingsDefId);
  const schedulerData = await getPageData(sdk, appToken, schedulerSectionRef);
  const schedulerStyle = await getSchedulerStyles(schedulerSectionStructure.style, colorsMap);
  console.log('New Properties:');
  console.table(schedulerStyle);
  await migratory.migrateSection(schedulerSectionStructure, WidgetsId.BOOKINGS_LIST, PageId.BOOKINGS_LIST);
  try {
    enableProgressBar && await setBarStep(sdk, appToken, translation,'bookings-migration.progress-bar.step', 2);
    const bookCheckoutPage = await addBookCheckoutPage(sdk, bookingsDefId, appToken, schedulerSectionStructure.style);
    let bookCheckoutPageData = await getPageData(sdk, appToken, bookCheckoutPage.pageRef);
    schedulerData.pageBackgrounds.desktop.ref.id = bookCheckoutPageData.pageBackgrounds.desktop.ref.id;
    schedulerData.pageBackgrounds.mobile.ref.id = bookCheckoutPageData.pageBackgrounds.mobile.ref.id;
    await updatePageData(sdk, appToken, bookCheckoutPage.pageRef, {pageSecurity: {...schedulerData.pageSecurity}, pageBackgrounds: {...schedulerData.pageBackgrounds}});
  } catch {
    console.log('Checkout page already exists, skipping this');
  }
  await addBookingsPagesAsPanel(sdk, appToken);
  const compId = schedulerSectionStructure.id;
  enableProgressBar && await setBarStep(sdk, appToken, translation, 'bookings-migration.progress-bar.step',3);
  return setBookingsListStyles(sdk, appToken, schedulerStyle, compId)
    .then(async () => {
      await navigateToBookingsList(sdk, appToken);
      console.log('Will Resolve Now');
      enableProgressBar && await closeBar(sdk, appToken, false);
      return Promise.resolve();
    })
    .catch(async e => {
      enableProgressBar && await closeBar(sdk, appToken, true);
      return Promise.reject('Failed when setting styles: ' + e.message);
    });
}
